<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <confirm-modal ref="confirmModal" />
    <div class="common-department">
      <!-- sticky action menu bar -->
      <div :class="getTableHeaderClass + ' action-menu'" :style="{top: headerHeight + 'px'}">
        <div class="user-infor">
          <span>{{ countryAndLanguage }}</span>
        </div>
        <nav class="navbar navbar-expand-lg navbar-light topnav">
          <div id="navbarText" class="collapse navbar-collapse">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item">
                <router-link
                  exact
                  class="nav-link pr-0 pl-0"
                  to="/questionnaire"
                  @click.native="changeLink('questionnaire')"
                >
                  Questionnaire
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  exact
                  class="nav-link pr-0 pl-0"
                  to="/questionnaire/prescription"
                  @click.native="changeLink('prescription')"
                >
                  Prescription
                </router-link>
              </li>
            </ul>
            <!------------- edit range-------------------------->
            <ul class="navbar-nav ml-auto padding-right">
              <li class="nav-item">
                <template v-if="!editable">
                  <div style="display:flex">
                    <img
                      v-show="type == 'questionnaire' && showURL"
                      class="pointer"
                      style="height:39px;width:39px;margin-right:10px"
                      src="@/assets/icons/view_unselected.png"
                      @click="clickImage($event)"
                    />
                    <b-button
                      variant="outline-info custom-btn"
                      @click="turnOnEditable()"
                    >
                      Edit content
                    </b-button>
                  </div>
                </template>
                <template v-else>
                  <b-button
                    variant="outline-info custom-btn btn-save"
                    @click="saveAll()"
                  >
                    Save
                  </b-button>
                  <b-button
                    variant="outline-info custom-btn"
                    @click="turnOffEditable()"
                  >
                    Cancel
                  </b-button>
                </template>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <router-view />
      <!-- <confirm-modal :trigger-modal="isTriggedModal" @agree="isAgree = $event" /> -->
      <!-- Space end page -->
      <div style="height: 50px"></div>
    </div>
    <!--######################### The Modal ERRORR ###########################################################################-->
    <div v-if="isShowError">
      <transition name="modal">
        <div class="modal-mask-confirm">
          <div class="modal-wrapper">
            <div
              class="modal-dialog"
              role="document"
            >
              <div class="modal-content custom-dialog-confirm">
                <div class="modal-body">
                  <div class="body-text-modal-confirm">
                    <p class="mb-0" v-html="getMessage" />
                  </div>
                  <div
                    class="btn-modal-confirm"
                  >
                    <button
                      type="button"
                      class="btn btn-light"
                      style="color:#006DB6; margin-left:20px; width: 127px; height: 40px;"
                      @click="updateShowError(false)"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!--######################### The Modal ERRORR END ###########################################################################-->
  </div>
</template>

<script>
const QUESTIONNAIRE_TYPE = "questionnaire";
const PRESCRIPTION_TYPE = "prescription";
import { SURVEY_FE_URL } from "@/constants/Paths";
import _ from "lodash";
import { mapActions, mapGetters } from 'vuex';
import { MODAL_CANCEL, MODAL_SAVE, OK_CONFIRM, addPropertyData, addPropertyDataArr, changePropertyData, copyObjectProperty, reformatData } from "../../common/CommonUtils";
import QuestionnaireService from "../../service/questionnaire/QuestionnaireService";
import ConfirmModal from "../common/ConfirmModal.vue";

const isPrescriptionPath = (path) =>
  /\/questionnaire\/prescription\/?/.test(path);
const isQuestionnairePath = (path) => /\/questionnaire\/?/.test(path);

export default {
  components: {
    ConfirmModal,
  },
  data() {
    const surveyId = this.$store.state.survey.id;
    console.log(this.$store.state.survey);
    return {
      surveyId: surveyId <= 0 ? 1 : surveyId,
      showURL: this.$store.state.survey.showURL,
      type: QUESTIONNAIRE_TYPE,
      editable: false,
    };
  },
  computed: {
    ...mapGetters([
        'isEditable',
        'isShowError',
        'getMessage',
    ]),
    // editable() {
    //   return isPrescriptionPath(this.$router.currentRoute.path)
    //     ? this.$store.state.questionnaire.prescription.editStatus
    //     : this.$store.state.questionnaire.questionnaire.editStatus;
    // },
    countryAndLanguage() {
      return this.$store.state.survey.countryAndLanguage;
    },
    headerHeight() {
      return this.$store.state.common.theHeaderHeight
    },
    getTableHeaderClass() {
        return this.editable
            ? "action-menu-edit"
            : ""
    },
  },
  beforeCreate() {
    const _this = this;
    let surveyId = this.$store.state.survey.id;
    surveyId = surveyId <= 0 ? 1 : surveyId;

    if (isPrescriptionPath(this.$router.currentRoute.path)) {
      let loader = this.$loading.show();
      QuestionnaireService.getPrescription(surveyId).then((res) => {
        _this.$store.dispatch("questionnaire/initialPrescriptionData", {
          data: res,
        });
        _this.type = PRESCRIPTION_TYPE;
        loader.hide();
      });
    } else if (isQuestionnairePath(this.$router.currentRoute.path)) {
      let loader = this.$loading.show();
      QuestionnaireService.getQuestionnaire(surveyId).then((res) => {
        _this.$store.dispatch("questionnaire/initialQuestionnaireData", {
          data: res,
        });
        _this.type = QUESTIONNAIRE_TYPE;
        loader.hide();
      });
    }
    this.$store.commit("questionnaire/changeEditStatus", {
      type: QUESTIONNAIRE_TYPE,
      data: false,
    });
    this.$store.commit("questionnaire/changeEditStatus", {
      type: PRESCRIPTION_TYPE,
      data: false,
    });
  },
  methods: {
    ...mapActions([
        'updateMessage',
        'updateShowError',
    ]),
    cancelEdit() {
      if (this.type === QUESTIONNAIRE_TYPE) {
        const questionnaireData = _.cloneDeep(
          this.$store.state.questionnaire.questionnaire.questionnaireOriginData
        );

        const addTempProperty = addPropertyDataArr(questionnaireData, [
          { propertyName: "isEdit", value: false },
          { propertyName: "editData", value: "" },
        ]);

        const editData = copyObjectProperty(
          addTempProperty,
          "data",
          "editData"
        );
        this.$store.commit("questionnaire/changeQuestionnaireData", {
          data: editData,
        });
      } else {
        const prescriptionData = _.cloneDeep(
          this.$store.state.questionnaire.prescription.prescriptionOriginData
        );
        this.$store.commit("questionnaire/changePrescriptionData", {
          data: addPropertyData(prescriptionData, {
            propertyName: "isEdit",
            value: false,
          }),
        });
      }

      this.$store.commit("questionnaire/changeEditStatus", {
        type: this.type,
        data: false,
      });
      this.changeEditableState(false);
    },
    // eslint-disable-next-line no-unused-vars
    clickImage($event) {
      const survey = this.$store.state.survey;
      window.open(`${SURVEY_FE_URL}?surveyId=${survey.parentId || survey.id}`);
    },
    changeEditableState(state) {
      this.editable = state;
      this.$store.state.survey.isSurveyEditing = state;
      if (state === true) {
        this.$store.state.survey.editingScreenSaveFunc = this.saveAll;
      }
    },
    turnOnEditable() {
      this.$store.commit("questionnaire/changeEditStatus", {
        type: this.type,
        data: true,
      });
      this.changeEditableState(true);
      if (this.type === QUESTIONNAIRE_TYPE) {
        const questionnaireData = _.cloneDeep(
          this.$store.state.questionnaire.questionnaire.questionnaireData
        );
        const editData = changePropertyData(questionnaireData, {
          propertyName: "isEdit",
          value: true,
        });
        this.$store.commit("questionnaire/changeQuestionnaireData", {
          data: editData,
        });
      } else {
        const prescriptionData = _.cloneDeep(
          this.$store.state.questionnaire.prescription.prescriptionData
        );
        const editData = changePropertyData(prescriptionData, {
          propertyName: "isEdit",
          value: true,
        });

        this.$store.commit("questionnaire/changePrescriptionData", {
          data: editData,
        });
      }
    },

    turnOffEditable() {
      this.$refs.confirmModal.open(MODAL_CANCEL, (data) => {
        if (data === OK_CONFIRM) {
          this.cancelEdit();
        }
      });
    },

    changeLink(type) {
      this.type = type;
      let loader = this.$loading.show();
      if (type === QUESTIONNAIRE_TYPE) {
        QuestionnaireService.getQuestionnaire(this.surveyId).then((res) => {
          this.$store.dispatch("questionnaire/initialQuestionnaireData", {
            data: res,
          });
          loader.hide();
        });
      }

      if (type === PRESCRIPTION_TYPE) {
        QuestionnaireService.getPrescription(this.surveyId).then((res) => {
          this.$store.dispatch("questionnaire/initialPrescriptionData", {
            data: res,
          });
          loader.hide();
          window.location.reload();
        });
      }
    },

    saveEdit(chainFunc) {
      this.$store.commit("questionnaire/changeEditStatus", {
        type: this.type,
        data: false,
      });
      if (this.type === QUESTIONNAIRE_TYPE) {
        const questionnaireData = _.cloneDeep(
          this.$store.state.questionnaire.questionnaire.questionnaireData
        );
        let isValidate = false
        if(questionnaireData[6] && questionnaireData[6].options) {
          for (const [key, value] of Object.entries(questionnaireData[6].options)) {
            if(["data","data1","data2","data3","data4",].includes(key)) isValidate = this.checkRegexQuestionnaire(value, isValidate);
          }
        }
        if(isValidate) {
          const message = `
            <p class="mb-0">Please remove the full stop at the end of a bracket.</p>
            <p class="mb-0">Example of error: title(description).</p>
            <p class="mb-0">Example after removing full stop: title(description)</p>
          `;
          this.updateMessage(message);
          this.updateShowError(true);
          return;
        }
        this.changeEditableState(false);
        const editDataUI = changePropertyData(questionnaireData, {
          propertyName: "isEdit",
          value: false,
        });
        this.$store.commit("questionnaire/changeQuestionnaireData", {
          data: editDataUI,
        });

        const originData = this.$store.state.questionnaire.questionnaire
          .questionnaireOriginData;
          console.log(editDataUI);
        const getReformatData = reformatData(
            originData,
          editDataUI,
          "editData"
        );
        // call api
        let loader = this.$loading.show();
        const response = QuestionnaireService.updateQuestionnaire(
          this.surveyId,
          getReformatData
        );
        //update to origin
        response &&
          response
            .then((res) => {
              loader.hide();
              this.$store.dispatch("questionnaire/initialQuestionnaireData", {
                data: res,
              });
              if (chainFunc !== undefined)
                chainFunc()
            })
            .catch((e) => {
              loader.hide();
              console.error(e);
            });
      } else {
        const prescriptionData = _.cloneDeep(
          this.$store.state.questionnaire.prescription.prescriptionData
        );
        const validate = {};
        let  isValidate = false;
        prescriptionData[1].defaults.forEach((values, index) => {
          const options = prescriptionData[1].options[index].data.split(/\s*,\s*/);
          const min = Number(options[0]);
          const max = Number(options[index !== 6 ? options.length - 1 : options.length - 2]);
          if(index === 3) {
            const value = values.data !== '' ? Number(values.data) : '';
            if(isNaN(value) && (value % 1 !== 0 || value < min || value > max)){
              isValidate = true;
              validate.label = "READING DISTANCE";
            }
          }
          if(index === 4) {
            const value = values.data !== '' ? Number(values.data) : 0;
            if(Math.abs(value) % .25 !== 0 || value < min || value > max) {
              isValidate = true;
              validate.label = "SPHERE";
            }
          }
          if(index === 5) {
            const value = values.data !== '' ? Number(values.data) : 0;
            if(Math.abs(value) % .25 !== 0 || value < min || value > max) {
              isValidate = true;
              validate.label = "CYLINDER";
            }
          } 
          if(index === 6) {
            const value = values.data !== '' ? Number(values.data) : 0;
            if(value % 1 !== 0 || value < min || value > max) {
              isValidate = true;
              validate.label = "AXIS";
            }
          }
          if(index === 7) {
            const value = values.data !== '' ? Number(values.data) : 0;
            if(value % .25 !== 0 || value < min || value > max) {
              isValidate = true;
              validate.label = "ADDITION";
            }
          }
        });
        if(isValidate) {
          this.updateMessage(validate.label + ' - Default value is incorrect.');
          this.updateShowError(true);
          return;
        }else {
          const editDataUI = changePropertyData(prescriptionData, {
            propertyName: "isEdit",
            value: false,
          });
          this.$store.commit("questionnaire/changePrescriptionData", {
            data: editDataUI,
          });
          this.changeEditableState(false);
          const originData = this.$store.state.questionnaire.prescription.prescriptionOriginData;
          let mandatoryData = "";
          if (editDataUI[1].options[6].data.includes('{true}')) {
              mandatoryData = editDataUI[1].options[6].data.replaceAll('{true} MANDATORY', '');
              mandatoryData = mandatoryData + '{true} MANDATORY';
          } else {
              mandatoryData = editDataUI[1].options[6].data.replaceAll('{false} MANDATORY', '')
              mandatoryData = mandatoryData + '{false} MANDATORY';
          }
          editDataUI[1].options[6].data = mandatoryData;
          const getReformatData = reformatData(originData, editDataUI, "data");
          // call api
          let loader = this.$loading.show();
          const response = QuestionnaireService.updatePrescription(
            this.surveyId,
            getReformatData
          );
          //update to origin
          response &&
            response
              .then((res) => {
                // chain validate null
                if (chainFunc !== undefined)
                  chainFunc()
                loader.hide();
                this.$store.dispatch("questionnaire/initialPrescriptionData", {
                  data: res,
                });
              })
              .catch((e) => {
                loader.hide();
                console.error(e);
              });
        }
        }

    },
    checkRegexQuestionnaire(data, isValidate) {
      if(data && !/^[^(]*\([^()]*\)$/.test(data.editData) || data.editData.charAt(0) === "(") {
        return true;
      } return isValidate;
    },

    saveAll(chainFunc) {
      this.$refs.confirmModal.open(MODAL_SAVE, (data) => {
        if (data === OK_CONFIRM) {
          this.saveEdit(chainFunc);
        }
      });
    },
  },
};
</script>

<style scoped>
.nav-link {
  font-size: 20px;
}

.nav-link {
  font-size: 20px;
}

@media (min-width: 576px) {
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: table;
    transition: opacity .3s ease;
  }
  
  .modal-mask-confirm {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: hsla(0, 0%, 71%, 0.5);
    display: table;
    transition: opacity .3s ease;
  }

.custom-modal-dialog {
    margin: 0 auto !important;
    max-width: 100% !important;
    display: flex;
    justify-content: center;
  }
  
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  
  .custom-dialog {
    width: 1034px;
    height: 602px;
    background: white;
  }
  
  .custom-dialog-confirm {
    width: 467px;
    height: 283px;
    background: #4B4B4B;
  }
  .custom-dialog-confirm .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .modal-custom-header {
    background: #E5F4FF;
    margin: 0px 0px 15px 0px;
    padding: 10px;
  }
  
  .modal-custom-body {
    background: white;
    margin: 0px;
    padding: 0px 25px;
  }
  
  .modal-custom-body-row {
    background: #f6f6f6;
    margin: 0px 25px;
    padding: 0px;
  }

.modal-custom-product {
    background: white;
    margin: 0px 25px;
    min-height: 150px;
  }
  
  .body-text-modal{
    text-align: left;
    font-size: 15px;
    line-height: 23px;
    color: black;
  }
  
  .body-text-modal-confirm {
    /* width: 294px; */
    /* height: 56px; */
    margin: 0 auto;
    text-align: center;
    /* padding: 60px 0px; */
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 25px;
    color: white;
  }
  
  .btn-modal {
    float: right;
  }
  .dis_checkbox {
    pointer-events: none;
  }
  .btn-modal-confirm {
    display: flex;
    justify-content: center;
  }
</style>
