/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
// import _ from 'lodash';
import { isEmpty, isNull } from "../../common/CommonUtils";
import { http } from "../../plugin/http2-common";
import PrescriptionMock from "./mock/PrescriptionMock";
// import QuestionnaireMock from "./mock/QuestionnaireMock";
import QuestionnaireMockNew from "./mock/QuestionnaireMockNew";

const PRESCRIPTION_UPDATE_API = "/questionnaire/prescription/update/";
const QUESTIONNAIRE_UPDATE_API = "/questionnaire/update/";

const PRESCRIPTION_GET_API = "/questionnaire/prescription/list/";
const QUESTIONNAIRE_GET_API = "/questionnaire/list/";

/**
 * custom on first version, ....will change many time
 */
const formatQuestionnaireResponse = (responseData) => {
  return responseData.map((question) => {
    const format = {
      id: question.id,
      options: isNull(question.options) ? { data: "" } : question.options,
      shortName: isNull(question.shortName) ? { data: "" } : question.shortName,
      shortText: isNull(question.shortText) ? { data: "" } : question.shortText,
      question: isNull(question.question) ? { data: "" } : question.question,
      description: isNull(question.description)
        ? { data: "" }
        : question.description,
    };
    format.maxRowLength = Math.max(
      Object.keys(question.options).length,
      Object.keys(question.description).length,
      Object.keys(question.shortText).length
    );

    return format;
  });
};

/**
 * custom on first version, ....will change many time
 */
const formatQuestionnaireRequest = (
  requestData,
  isQuestionnaireFirstUpdate = false
) => {
  return requestData.map((question) => {
    const questionClone = { ...question };
    if (isQuestionnaireFirstUpdate) {
      delete questionClone.id;
    }
    return questionClone;
  });
};

/**
 * custom on first version, ....will change many time
 */
const formatPrescriptionRequest = (
  requestData,
  isPrescriptionFirstUpdate = false
) => {
  return requestData.map((question) => {
    const questionClone = { ...question };
    if (isPrescriptionFirstUpdate) {
      delete questionClone.id;
    }
    return questionClone;
  });
};

class QuestionnaireService {
  static isQuestionnaireFirstUpdate = false;

  static isPrescriptionFirstUpdate = false;

  getQuestionnaire(surveyId) {
    return http
      .get(QUESTIONNAIRE_GET_API + surveyId)
      .then((response) => {
        if (isEmpty(response.data.result)) {
          QuestionnaireService.isQuestionnaireFirstUpdate = true;
          return QuestionnaireMockNew;
        }
        const resFormat = formatQuestionnaireResponse(response.data.result);
        return resFormat;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getPrescription(surveyId) {
    return http
      .get(PRESCRIPTION_GET_API + surveyId)
      .then((response) => {
        if (isEmpty(response.data.result)) {
          QuestionnaireService.isPrescriptionFirstUpdate = true;
          return PrescriptionMock;
        }
        return response.data.result;
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updateQuestionnaire(surveyId, data) {
    const dataFormatRequest = formatQuestionnaireRequest(
      data,
      QuestionnaireService.isQuestionnaireFirstUpdate
    );

    return http
      .put(QUESTIONNAIRE_UPDATE_API + surveyId, dataFormatRequest)
      .then((response) => {
        QuestionnaireService.isQuestionnaireFirstUpdate = false;
        return formatQuestionnaireResponse(response.data.result);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  updatePrescription(surveyId, data) {
    return http
      .put(
        PRESCRIPTION_UPDATE_API + surveyId,
        formatPrescriptionRequest(
          data,
          QuestionnaireService.isPrescriptionFirstUpdate
        )
      )
      .then((response) => {
        QuestionnaireService.isPrescriptionFirstUpdate = false;
        return response.data.result;
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

export default new QuestionnaireService();
