const data = [
  {
    id: 1,
    prescription: ["Previous design"],
    units: [""],
    options: [
      "Single Vision Lenses",
      "Progressive Lenses",
      "Reading Glasses",
      "Mid PAL",
      "None",
    ],
  },
  {
    id: 2,
    prescription: [
      "Right(OD)",
      "Left(OS)",
      "Age",
      "Reading distance",
      "Sphere",
      "Cylinder",
      "Axis",
      "Addition",
    ],
    units: ["", "", "", "cm", "", "", "", "D"],
    options: [
      "-",
      "-",
      "-",
      "40",
      "-20,-19,-18,-17,-16,-15,-14,-13,-12,-11,-10,-9,-8,-7,-6,-5,-4,-3,-2,-1,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20",
      "-20,-19,-18,-17,-16,-15,-14,-13,-12,-11,-10,-9,-8,-7,-6,-5,-4,-3,-2,-1,0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20",
      "0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,94,95,96,97,98,99,100,101,102,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180",
      "0, 0.25, 0.5, 0.75, 1.00, 1.25, 1.50, 1.75, 2.00, 2.25, 2.50, 2.75, 3.00, 3.25, 3.50",
    ],
    // options: [
    //   { value: "-", max: 0, min: 0 },
    //   { value: "-", max: 0, min: 0 },
    //   { value: "-", max: 0, min: 0 },
    //   { value: "40", max: 0, min: 0 },
    //   { value: "-", max: 20, min: -20 },
    //   { value: "-", max: 20, min: -20 },
    //   { value: "-", max: 0, min: 180 },
    //   {
    //     value:
    //       "- 1.00, 1.25, 1.50, 1.75, 2.00, 2.25, 2.50, 2.75, 3.00, 3.25, 3.50",
    //     max: 0,
    //     min: 0,
    //   },
    // ],
  },
];

export default data;
