const data = [
  {
    id: 1,
    shortName: { data: "Intro" },
    shortText: { data: "" },
    question: {
      data: `Take our 3-minute questionnaire and tell us more about your lifestyle. Based on your answers, we will provide you with tailor-made lens solutions adapted to your visual needs.`,
      data1: `Let’s discover which Nikon lens is made for you.`,
    },
    description: {},
    options: {},
  },
  {
    id: 2,
    shortName: { data: "Privacy" },
    shortText: { data: "" },
    question: {
      data: `By participating in this questionnaire, I agree to the privacy statement.`,
      data1: `Read the full statement here`,
    },
    description: {},
    options: { data: true, data1: "relaxsee.com" },
  },
  {
    id: 3,
    shortName: { data: "Emailer" },
    shortText: { data: "" },
    question: {
      data: `I hereby give consent to Nikon Optical Inc. to send me commercial offers by e-mail.`,
    },
    description: {},
    options: {},
  },
  {
    id: 4,
    shortName: { data: "Button" },
    shortText: { data: "" },
    question: { data: "Tap here to continue" },
    description: {},
    options: {},
  },
  {
    id: 5,
    shortName: { data: "Qn 1" },
    shortText: { data: "" },
    question: { data: "What is your name?" },
    description: {},
    options: {},
  },
  {
    id: 6,
    shortName: { data: "Qn 2" },
    shortText: { data: "" },
    question: { data: "How old are you" },
    description: {},
    options: {},
  },
  {
    id: 7,
    shortName: { data: "Qn 3" },
    shortText: { data: "Visual difficulties" },
    question: { data: "What are some visual difficulties you face?" },
    description: { data: "Select those that apply" },
    options: {
      data:
        "Near objects are blurry(e.g. Reading Newspaper, using mobile phone)",
      data1: "Astigmatism(Both far and near objects are distorted)",
      data2:
        "Mid-distance objects are blurry(e.g. Across the room, presentation screens)",
      data3: "Double Vision(Seeing two images, eyes not lining up)",
      data4: "Far objects are blurry(e.g. Driving, walking down the street)",
      data5: "None",
    },
    maxRowLength: 6,
  },
  // q7- update
  {
    id: 8,
    shortName: { data: "Qn 4" },
    shortText: { data: "Indoor houses" },
    question: { data: "On an average, where do you spend most of your time?" },
    description: {},
    options: { data: "Indoors", data1: "Outdoors" },
    maxRowLength: 2,
  },
  {
    id: 9,
    shortName: { data: "Qn 5" },
    shortText: { data: "Eye fatigue" },
    question: {
      data: "When looking at digital screens, do you experience eye fatigue?",
    },
    description: {
      data: "E.g. Difficulty focusing, blurry vision, dry eyes, headaches.",
    },
    options: { data: "Yes", data1: "No" },
    maxRowLength: 2,
  },
  {
    id: 10,
    shortName: { data: "Qn 6" },
    shortText: { data: "Day glare" },
    question: {
      data: "Are you sensitive to sunlight or glare during the day?",
    },
    description: {},
    options: { data: "Yes", data1: "No" },
    maxRowLength: 2,
  },
  {
    id: 11,
    shortName: { data: "Qn 7" },
    shortText: { data: "Drive Glare" },
    question: { data: "When driving, are you bothered by:" },
    description: {},
    options: {
      data: "Headlight glare at night",
      data1: "Sun glare during the day",
      data2: "None / I don’t drive",
    },
    maxRowLength: 3,
  },
  {
    id: 12,
    shortName: { data: "Qn 8" },
    shortText: { data: "Dull colour vision" },
    question: {
      data:
        "Do colours seem less bright than in the past, especially when it gets dark?",
    },
    description: {
      data: "Eg. Reading at night, reading in dim places like restaurants.",
    },
    options: { data: "Yes", data1: "No" },
    maxRowLength: 2,
  },
  // index 12
  {
    id: 13,
    shortName: { data: "Qn 9" },
    shortText: {
      data: "Mobile phone",
      data1: "Tablet/ebook",
      data2: "Laptop",
      data3: "Desktop Computer",
      data4: "Watch TV",
      data5: "Water/winter actions",
      data6: "Read & Write",
      data7: "Crafts",
      data8: "Restaurants(1m)",
      data9: "Meeting(3m)",
      data10: "Indoor sports",
      data11: "Outdoor Activities",
      data12: "Drive",
    },
    question: { data: "How many hours in a day do you..." },
    description: {
      data: "Use a mobile phone",
      data1: "Use a tablet or ebook",
      data2: "Use a laptop",
      data3: "Use a desktop computer",
      data4: "Watch TV",
      data5: "Read & Write",
      data6: "Cook",
      data7: {
        data: "Do handicrafts",
        data1: "e.g. Sewing, electronic tinkering, painting",
      },
      data8: {
        data: "Eat in restaurants",
        data1: "e.g. Cook meal, eat with people at the same table",
      },
      data9: {
        data: "Attend meetings, or stroll indoors",
        data1: "e.g. Meeting room, conference, library, museum",
      },
      data10: {
        data: "Spend on indoor sports",
        data1: "e.g. Gyming, dancing, yoga",
      },
      data11: {
        data: "Spend on water/winter activities",
        data1: "e.g. Fishing, skiing, canoeing",
      },
      data12: "Drive",
    },
    options: { data: "0-16", data1: "hours" },
    maxRowLength: 13,
  },
  {
    id: 14,
    shortName: { data: "End" },
    shortText: { data: "" },
    question: {
      data:
        "Your answers give us a clear understanding of your visual needs according to your lifestyle and activities.",
      data1:
        "Submit your answers to discover the perfect Nikon Lenswear solutions made for you.",
    },
    description: {},
    options: {},
  },
  {
    id: 15,
    shortName: { data: "Button" },
    shortText: { data: "" },
    question: { data: "Submit" },
    description: {},
    options: {},
  },
];

export default data;
